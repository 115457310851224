<template>
  <div class="head-sec" :class="thisclass ? 'scroll' : ''">
    <div class="hd-bar">
      <div class="auto clearfix">
        <div class="hd-wz fl">欢迎您的到来~</div>
        <div class="hd-sear fr flex flex-align-center" id="search">
          <input
            type="text"
            class="ipt ipt-txt"
            placeholder="站内搜索"
            v-model="keyword"
          />
          <em class="line"></em>
          <!-- <button class="submit"></button> -->

          <router-link
            :to="{ path: '/sousuo', query: { keyword: keyword } }"
            class="submit"
          ></router-link>
          <!-- <router-link :to="'/sousuo/'+keyword "  class="submit"></router-link> -->
        </div>
      </div>
    </div>
    <div class="head">
      <div class="auto">
        <router-link to="/" class="hd-logo fl">
          <img src="../assets/images/logo.png" class="img" />
        </router-link>
        <ul class="hd-nav fr" v-if="showNav">
          <li class="navList" :class="curPath == '/' ? 'cur' : ''">
            <router-link to="/">首页</router-link>
          </li>
          <li
            class="navList"
            v-for="(item, index) in navs"
            :key="index"
            :class="curPath == item.path ? 'cur' : ''"
            @mouseenter="headOverFun(item, index)"
            @mouseleave="headLeaveFun()"
          >
            <!-- @mouseenter="headOverFun(item,index)"
             @mouseleave="headLeaveFun()" -->
            <!-- v-show="navIndex == index && item.lists" -->
            <router-link :to="item.path">{{ item.name }}</router-link>
            <div class="lineHint" v-if="navIndex == index && item.lists">
              <div class="line"></div>
              <div class="triangle-down"></div>
            </div>
            <div
              class="navListBox"
              :class="navIndex == index && item.lists ? 'change' : 'change1'"
              v-if="navIndex == index && item.lists"
            >
              <div class="navBox">
                <div
                  class="itemList"
                  :class="itemListIndex == index ? 'itemListIndex' : ''"
                  v-show="listsNum > 1"
                  v-for="(itemList, index) in item.lists"
                  :key="index"
                  @click="firstFun(itemList, index)"
                  :title="itemList.names.length > 6 ? itemList.names : ''"
                >
                  {{ itemList.names }}
                </div>
              </div>
              <div class="navBox">
                <div class="titleList">
                  <div
                    class="titleBox"
                    v-for="(itemTitle, index) in firstDetailsList"
                    :key="index"
                  >
                    <div class="sanjiao"></div>
                    <router-link
                      :to="{
                        path: item.path,
                        query: {
                          itemId: itemTitle.ItemId,
                          rootId: rootIdNum,
                          supItemId: itemTitle.ParentId,
                          typeCode: itemTitle.TypeCode,
                        },
                      }"
                      class="title titles"
                      >{{ itemTitle.ItemName }}</router-link
                    >
                    <div class="dates">
                      {{
                        common.dateFormat(
                          "YYYY-mm-dd",
                          itemTitle.CrDate.toString()
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <em
          class="sj-menu"
          :class="showNav ? 'close' : ''"
          @click="
            () => {
              showNav = !showNav;
            }
          "
        ></em>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/utils/eventbus";
export default {
  name: "",
  data() {
    return {
      showNav: "",
      curPath: "/",
      keyword: "",
      navIndex: -1,
      itemListIndex: "",
      rootIdNum: 10000,
      listsNum: "",
      navs: [],
      firstDetailsList: [],
      thisclass: false,
      headList1: [],
    };
  },
  components: {},
  created() {
    // eventBus.on("menu", (val) => {
    //   console.log(val, "MenuVal");

    //   this.navs = val;
    // });
    this.curPath = this.$route.matched[0].path
      ? this.$route.matched[0].path
      : "/";

    // console.log(this.$route, "this.curPath");
    // console.log(this.navs, "navs");
    // console.log(this.navs, "navs222");
  },
  mounted() {
    this.$nextTick(() => {
      this.getMenu();
    });
    if (this._isMobile()) {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  // watch: {
  //   navs: {
  //     handler(newValue, oldValue) {
  //       this.navs = newValue;
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    getMenu() {
      let oldMenu = [
        {
          name: "院士",
          type: "yuanshijianjie",
          path: "/yuanshijianjie",
          code: "Basic_Intro",
          page: "views/yuanshijianjie",
        },
        {
          name: "核心技术",
          type: "hexinjishu",
          path: "/hexinjishu",
          code: "Basic_KJCX",
          page: "views/kejichuangxin",
          lists: [
            {
              names: "高坝工程",
              itemIdNum: "115",
              typeCodeNum: "CX027",
            },
            {
              names: "水网工程",
              itemIdNum: "117",
              typeCodeNum: "CX029",
            },
            {
              names: "数字工程",
              itemIdNum: "119",
              typeCodeNum: "CX031",
            },
            {
              names: "新型电力系统与储能工程",
              itemIdNum: "118",
              typeCodeNum: "CX030",
            },
            {
              names: "其他",
              itemIdNum: "120",
              typeCodeNum: "CX032",
            },
          ],
        },
        {
          name: "学术成果",
          type: "xueshuchengguo",
          path: "/xueshuchengguo",
          code: "Basic_CXCG",
          page: "views/kejichengguo",
          lists: [
            {
              names: "专利",
              itemIdNum: "16",
              typeCodeNum: "CX005",
            },
            {
              names: "专著",
              itemIdNum: "17",
              typeCodeNum: "CX006",
            },
            {
              names: "论文",
              itemIdNum: "18",
              typeCodeNum: "CX007",
            },
            {
              names: "标准",
              itemIdNum: "19",
              typeCodeNum: "CX008",
            },
            {
              names: "软著",
              itemIdNum: "20",
              typeCodeNum: "CX009",
            },
            {
              names: "科研报告",
              itemIdNum: "21",
              typeCodeNum: "CX010",
            },
          ],
        },
        {
          name: "荣誉奖励",
          type: "rongyujiangli",
          path: "/rongyujiangli",
          code: "Basic_CXJL",
          page: "views/kejijiangli-personal",
          lists: [
            {
              names: "人才奖励",
              itemIdNum: "23",
              typeCodeNum: "CX012",
            },
            {
              names: "科技奖励",
              itemIdNum: "24",
              typeCodeNum: "CX013",
            },
            {
              names: "工程奖励",
              itemIdNum: "40",
              typeCodeNum: "CX040",
            },
          ],
        },
        {
          name: "战略咨询",
          type: "zhanluezixun",
          path: "/zhanluezixun",
          code: "Basic_SGRZ_66",
          page: "views/zhanluezixun",
          lists: [
            {
              names: "战略咨询",
              itemIdNum: "25",
              typeCodeNum: "CX014",
            },
          ],
        },
        {
          name: "工程咨询",
          type: "gongchengzixun",
          path: "/gongchengzixun",
          code: "Basic_SGRZ_68",
          page: "views/gongchengzixun",
          lists: [
            {
              names: "工程咨询",
              itemIdNum: "26",
              typeCodeNum: "CX015",
            },
          ],
        },
        {
          name: "科技项目",
          type: "kejixiangmu",
          path: "/kejixiangmu",
          code: "Basic_SGRZ_67",
          page: "views/kejigongguan",
          lists: [
            {
              names: "科技项目",
              itemIdNum: "716",
              typeCodeNum: "CX034",
            },
          ],
        },
        {
          name: "人才培养",
          type: "rencaipeiyang",
          path: "/rencaipeiyang",
          code: "Basic_CXRC",
          page: "views/rencaipeiyang",
          lists: [
            {
              names: "领军人才",
              itemIdNum: "27",
              typeCodeNum: "CX016",
            },
            {
              names: "博士后",
              itemIdNum: "28",
              typeCodeNum: "CX017",
            },
            {
              names: "博士",
              itemIdNum: "29",
              typeCodeNum: "CX018",
            },
            {
              names: "硕士",
              itemIdNum: "30",
              typeCodeNum: "CX019",
            },
          ],
        },
        {
          name: "创新研究院",
          type: "chuanxinyanjiuyuan",
          path: "/chuanxinyanjiuyuan",
          code: "Basic_CXGZZ_01",
          page: "views/yuanshigongzuozhan",
          lists: [
            {
              names: "创新研究院",
              itemIdNum: "42",
              typeCodeNum: "CX033",
            },
          ],
        },
        {
          name: "学术活动",
          type: "xueshuhuodong",
          path: "/xueshuhuodong",
          code: "Basic_CXHD_01",
          page: "views/xueshujiaoliu",
          lists: [
            {
              names: "学术活动",
              itemIdNum: "31",
              typeCodeNum: "CX020",
            },
          ],
        },
        {
          name: "社会活动",
          type: "shehuihuodong",
          path: "/shehuihuodong",
          code: "Basic_CXHD_02",
          page: "views/shehuihuodong",
          lists: [
            {
              names: "社会活动",
              itemIdNum: "32",
              typeCodeNum: "CX021",
            },
          ],
        },
      ];
      $.ajax({
        type: "get", //请求方式不写就默认为get请求
        url: this.apiUrl + "/Api/YSPTApi/GetMenu",
      }).then((res) => {
        //数据请求成功，res为成功数据
        this.navs = res.Data.menu.map((item, index) => {
          var findData = oldMenu.find((i) => i.code === item.code);
          if (findData && findData.code == item.code) {
            item.path = findData.path;
            return item;
          }
        });
        console.log(this.navs, "navsnavs");
        console.log(this.$route.path);
        var curMenu = this.navs.find((obj) => obj.path == this.$route.path);
        console.log(curMenu, "curMenu");
        if (curMenu !== undefined) {
          eventBus.emit("navs", curMenu);
        }
      });
    },
    backTop() {
      document.documentElement.scrollTop = document.body.scrollTop;
    },
    flushCom(href) {
      //console.log(href)
      window.location.href = `${href}`;
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop)
      if (scrollTop > 1) {
        this.thisclass = true;
      } else {
        this.thisclass = false;
      }
    },
    headOverFun(item, index) {
      let that = this;
      that.navIndex = index;
      let lists = item.lists;
      if (lists == undefined || lists == null) {
        return;
      }
      that.listsNum = lists.length;
      that.firstFun(lists[0], 0);
    },
    headLeaveFun() {
      let that = this;
      that.navIndex = -1;
      // that.firstDetailsList=[];
    },
    firstFun(conent, index) {
      let that = this;
      that.itemListIndex = index;
      if (conent.itemIdNum == "115") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList1"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "16") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList2"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "23") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList3"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "25") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList4"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "26") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList5"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "27") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList6"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "31") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList7"));
        if (this.firstDetailsList) {
          return;
        }
      }
      if (conent.itemIdNum == "32") {
        this.firstDetailsList = JSON.parse(sessionStorage.getItem("headList8"));
        if (this.firstDetailsList) {
          return;
        }
      }

      // that.firstDetailsList=[];
      var obj = {
        rootId: that.rootIdNum,
        itemId: conent.itemIdNum,
        typeCode: conent.typeCodeNum,
        itemOrdey: true,
        userId: this.userToken,
        DisableItemContent: true,
      };
      var centent = JSON.stringify(obj);
      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          var tmpArr = [];
          for (var index in datas.Items) {
            if (index <= 4) {
              tmpArr.push(datas.Items[index]);
            }
          }
          that.firstDetailsList = tmpArr;

          if (conent.itemIdNum == "115") {
            sessionStorage.setItem("headList1", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "16") {
            sessionStorage.setItem("headList2", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "23") {
            sessionStorage.setItem("headList3", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "25") {
            sessionStorage.setItem("headList4", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "26") {
            sessionStorage.setItem("headList5", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "27") {
            sessionStorage.setItem("headList6", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "31") {
            sessionStorage.setItem("headList7", JSON.stringify(tmpArr));
          }
          if (conent.itemIdNum == "32") {
            sessionStorage.setItem("headList8", JSON.stringify(tmpArr));
          }
        },
      });
    },

    // 加载奖励列表
    // send(itemIdNum, typeCodeNum) {

    //   let that = this;
    //    var obj = {
    //          rootId: that.rootIdNum,
    //          itemId: itemIdNum,
    //          typeCode: typeCodeNum,
    //           itemOrdey:true,
    //           userId:this.userToken,
    //        };
    //        var centent = JSON.stringify(obj);
    //        $.ajax({
    //          type: "post",
    //          contentType: "application/json",
    //          url: this.apiUrl+"/Api/Source/Item",
    //          data: centent,
    //          success: function (res) {
    //            console.log(JSON.stringify(res))
    //            var datas = res.Data;
    //             aaa=datas.Items;

    //          },
    //        });
    //   callback(aaa)
    // },
  },
};
</script>

<style scoped>
.navList {
  position: relative;
  cursor: pointer;
  padding: 0 12px;
  margin: 0 0 0 2px;
}
.navList .lineHint {
  width: 80%;
  position: absolute;
  z-index: 1;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
}
.lineHint .line {
  width: 100%;
  height: 2px;
  background: #fff;
}
.lineHint .triangle-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  margin: 4px auto;
}
.flex_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}
.navList .navListBox {
  max-width: 810px;
  min-width: 270px;
  min-height: 40px;
  padding: 10px;
  background: #ecf2f9;
  border-radius: 0 0 6px 6px;
  border-top: none;
  border: 1px solid #024fa1;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  z-index: -1;
}
.navListBox .navBox {
  width: 400px;
  height: 100%;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
/* .navListBox .navBox:first-child{
  width: 100px;
} */
.navListBox .itemList {
  width: 120px;
  height: 35px;
  background: #145fc8;
  margin: 6px;
  padding: 0 5px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navListBox .itemList:hover {
  background: #2f7fee;
}
.navListBox .itemListIndex {
  background: #f0ae2e;
}
.titleList .titleBox:hover {
  color: #145fc8;
}
.titleList .titleBox {
  display: flex;
  justify-content: space-between;
  padding: 6px 4px;
  border-bottom: 1px dashed #ccc;
}
.titleBox .sanjiao {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 8px solid #145fc8;
  border-bottom: 4px solid transparent;
  margin-top: 6px;
}
.titleBox .titles {
  width: calc(100% - 100px);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-size: 14px;
}
.titleBox .dates {
  color: #999;
}
.titleBox .date {
  width: 120px;
  height: 100%;
}
.itemList .title {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.navBox .headline {
  font-weight: 600;
  height: 30px;
}
.navBox .titleList {
  width: 90%;
  height: calc(100% - 40px);
  margin: 0 auto;
}
.navBox .titleList {
  width: 100%;
  padding: 4px;
  white-space: nowrap;
  font-size: 12px;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.change {
  animation: myMou 0.5s;
  animation-fill-mode: forwards;
}
.change1 {
  animation: myMou1 0.5s;
  animation-fill-mode: forwards;
}
@keyframes myMou {
  0% {
    margin-top: -50px;
  }
  100% {
    margin-top: -31px;
  }
}
@keyframes myMou1 {
  0% {
    margin-top: -31px;
  }
  100% {
    margin-top: -50px;
  }
}
</style>
