<template>
	<div class="foot">
		<div class="auto">
			<div class="ft-t flex">
				<div class="ft-logo">
					<img src="../assets/images/ft_01.png" class="img1">
					<img src="../assets/images/ft_02.png" class="img2">
				</div>
				<div class="ft-info ">
					<div class="flex flex-pack-justify">
					<div class="col">
						<h6 class="tit">联系我们</h6>
						<div class="lks">
							<div class="fz"><i class="iconfont yt-dizhi"></i>地址：云南昆明人民东路115号</div>
							<div class="fz"><i class="iconfont yt-youjian"></i>邮箱：948906320@qq.com</div>
							<div class="fz"><i class="iconfont yt-phone"></i>电话：0871-63062050、0871-63062729</div>
							<div class="fz"><i class="iconfont yt-fankui"></i><a target="_blank" href="//mail.qq.com/cgi-bin/qm_share?t=qm_mailme&amp;email=948906320@qq.com" rel="external nofollow">意见反馈</a></div>

						</div>
					</div>
					<div class="ft-vx">
						<img src="../assets/images/ft_06.jpg" class="pic">
						<p class="wz">扫码关注公众号</p>
					</div>
					</div>
					<div class="ft-link">
						<div class="col">
						<h6 class="tit">友情链接</h6>
						<div class="lks flex flex-w">
							<a target="_blank" href="https://www.cae.cn/cae/html/main/index.html" class="lk">中国工程院</a>
							<a target="_blank" href="http://www.powerchina.cn/" class="lk">中国电建</a>
							<a target="_blank" href="http://www.khidi.com/" class="lk">中国电建昆明院</a>
							<a target="_blank" href="http://www.powerchina.cn/col/col7688/index.html" class="lk">中国电建网站群</a>
							<a target="_blank" href="http://www.csrme.com/" class="lk">中国岩石力学与工程学会</a>
							<a target="_blank" href="https://www.icold-cigb.org/" class="lk">国际大坝委员会</a>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div class="ft-b">
				版权所有：中国电建集团昆明勘测设计研究院有限公司&nbsp;&nbsp;&nbsp;&nbsp;备案号：滇ICP备20002565号
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		data() {
			return {
			}
		},
		components: {
			
		},
		created() {
			
		},
		methods: {
			
			
		}
	}
</script>

<style scoped>

</style>
